@keyframes opacityAnimation {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes arrowLeft {
    0% {
        left: 0;
    }
    50% {
        left: 20px;
    }
    100% {
        left: 0;
    }
}

@keyframes arrowRight {
    0% {
        right: 0;
    }
    50% {
        right: 20px;
    }
    100% {
        right: 0;
    }
}

.hero__contact__main {
    overflow: hidden;
    position: relative;
    min-height: 0vh;
    .sketh__images {
        width: 100%;
        &.bottom__sketch{
            position: absolute;
            bottom: 0;
            z-index: 3;
        }
    }

    .about__sub__section {
        width: 100%;

        .flex__section {
            padding: 5rem 0;
            display: flex;
            justify-content: space-between;

            .images__area {
                z-index: 1;
                width: 45%;
                display: flex;
                align-items: flex-end;
                position: relative;
                img{
                    max-width: 100%;
                    z-index: 1;
                    &.bulb__on{
                        position: absolute;
                        left: 0;
                        top: 0;
                        animation:opacityAnimation;
                        animation-duration: .5s;
                    }
                }
                .arrow__section{
                    top: 0;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        position: absolute;
                        max-width: 100px;
                        animation: arrowLeft 2s ease-in-out infinite;
                        &.right{
                            transform: rotate(180deg);
                            animation: arrowRight 2s ease-in-out infinite;
                        }
                    }
                }
            }

            .about__area {
                width: 45%;

                p{
                    font-size: 17px;
                    font-family: var(--geologica);
                    font-weight: 600;
                    color: var(--white);
                }
                .email__section{
                    a{
                        font-family: var(--geologica);
                        font-size: 17px;
                        color: var(--white);
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .line__section{
        margin-top: -160px;
        display: flex;
        flex-direction: column;
        gap: 100px;
    }
}

@media only screen and (max-width:1024px) {
    .hero__contact__main {
        .about__sub__section {

            .flex__section {
                padding: 1rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .line__section{
            display: none;
        }
    }
}

@media only screen and (max-width:525px) {
    .hero__contact__main {
        .about__sub__section {

            .flex__section {
                padding: 1rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                    .about__images {
                        img {
                           max-height: 300px;
                        }
                    }
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1461px){
    .hero__contact__main {
        .about__sub__section{
            .flex__section{
                // padding: 5rem 200px;
            }
        }
        .line__section{
            margin-top: -331px;
        }
    }
}

// @media only screen and (min-width:1700px){
//     .hero__contact__main {
//         .about__sub__section{
//             .flex__section{
//                 padding: 5rem 300px;
//             }
//         }
//     }
// }