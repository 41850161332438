.contact__about__main2 {
    position: relative;
    overflow: hidden;

    .sketh__images {
        width: 100%;

        &.bottom__sketch {
            position: absolute;
            bottom: 0;
        }
    }

    .about__sub__section {
        background-color: var(--pink);
        width: 100%;

        .flex__section {
            padding: 2rem 0;
            display: flex;

            .images__area {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                .img__container {
                    z-index: 1;
                    max-width: 100%;
                    img{
                        max-width: 100%;
                    }

                    .map__ball {
                        position: absolute;
                        top: 35px;
                        left: 25px;
                        max-width: 80%;
                    }
                }
            }

            .about__area {
                width: 50%;
                display: flex;
                align-items: center;

                p {
                    font-size: 17px;
                    font-family: var(--geologica);
                    font-weight: 600;
                }
            }
        }
    }

    .line__section {
        margin-top: -320px;
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
}

@media only screen and (max-width:1024px) {
    .contact__about__main2 {
        .about__sub__section {

            .flex__section {
                padding: 1rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }

                        .btn__section {
                            margin-top: 20px;
                        }
                    }
                    p{
                        text-align: center;
                    }
                    .btn__section{
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .contact__about__main2 {
        .about__sub__section {

            .flex__section {
                padding: 1rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                    margin-top: 30px;

                    .about__images {
                        img {
                            max-height: 300px;
                        }
                    }
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }

                        .btn__section {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}


// @media only screen and (min-width:1461px){
//     .contact__about__main2{
//         .about__sub__section{
//             .flex__section{
//                 padding: 2rem 200px;
//             }
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .contact__about__main2{
//         .about__sub__section{
//             .flex__section{
//                 padding: 2rem 300px;
//             }
//         }
//     }
// }