.about__reviews__main {
    width: 100%;
}

@media only screen and (max-width:1024px) {
    .reviews__main {
        .reviews__heading {
            h2 {
                font-size: 10rem;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .reviews__main {
        .reviews__heading {
            h2 {
                font-size: 5rem;
            }
        }
    }
}