.cta__btn{
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    background-color: var(--white);
    color: var(--black);
    border: none;
    outline: none;
    font-family: var(--geologica);
    font-weight: 600;
    font-style: italic;
    padding: 9px 24px;
    cursor: none !important;
    &.bgPink{
        background-color: var(--pink);
        color: var(--white);
    }
    &.padd__less{
        padding: 7px 10px;
        font-size: 14px;
        color: var(--black);
    }
    &.active{
        background-color: var(--black);
        color: var(--white);
    }
}