.other__communities__main {
    overflow: hidden;
    .sketh__images {
        width: 100%;
    }

    .other__communities__sub__section {
        background-color: var(--pink);
        width: 100%;

        .flex__section {
            // padding: 4rem 2rem;

            .cards__section {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 2rem;

                .card__custom {
                    width: 33%;
                    margin-top: 2.5rem;

                    .heading__section {
                        display: flex;
                        align-items: center;
                        gap: 7px;

                        .card__heading {
                            font-size: 28px;
                            line-height: 1.4;
                            font-family: var(--gobold);
                            color: var(--black);
                            font-weight: 500;
                        }
                    }

                    .card__description {
                        font-size: 12px;
                        font-family: var(--geologica);
                        color: white;
                        padding-right: 70px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .other__communities__main {

        .other__communities__sub__section {
            background-color: var(--pink);
            width: 100%;

            .flex__section {
                // padding: 4rem 100px;

                .cards__section {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-top: 2rem;

                    .card__custom {
                        width: 50%;

                        .heading__section {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 7px;
                            align-items: center;
                        }

                        .card__heading {
                            font-size: 26px;
                            text-align: center;
                        }

                        .card__description {
                            font-size: 12px;
                            padding: 0 40px;
                            margin-top: 10px;
                            text-align: center;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .other__communities__main {

        .other__communities__sub__section {
            background-color: var(--pink);
            width: 100%;

            .flex__section {
                // padding: 4rem 30px;

                .cards__section {
                    .card__custom {
                        width: 100%;

                        .card__description {
                            padding: 0 20px;
                        }
                    }
                }
            }
        }
    }
}

// @media only screen and (min-width:1461px){
//     .other__communities__main {
//         .other__communities__sub__section{
//             .flex__section{
//                 padding: 4rem 200px;
//             }
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .other__communities__main {
//         .other__communities__sub__section{
//             .flex__section{
//                 padding: 4rem 300px;
//             }
//         }
//     }
// }