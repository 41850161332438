@keyframes bird {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.contact__main {
    min-height: 100vh;

    .sketh__images {
        width: 100%;
    }
    .bg__pink{
        background-color: var(--pink);
    }
    .contact__sub__section {
        width: 100%;
        position: relative;

        .flex__section {
            min-height: 70vh;
            overflow: hidden;

            .contact__heading {
                h2 {
                    font-size: 250px;
                    font-family: var(--gobold);
                    line-height: 1.3;
                    line-height: 1;
                    margin-bottom: 0 !important;
                }
            }

            .text__section {
                // padding: 0 100px;

                p {
                    font-size: 52px;
                    font-family: var(--gobold);
                    line-height: 77px;
                    text-align: end;
                    color: var(--white);
                }
            }

            .back__box {
                width: 450px;
                border: 17px solid #c7c7c7;
                height: 550px;
                position: absolute;
                top: -33px;
                left: 280px;
            }

            .telephone__image {
                position: absolute;
                top: 2%;
                left: 10%;
                z-index: 9;
                width: 60%;
                animation: bird 13s ease-in-out infinite;
            }

            .connect_btn__section {
                margin-top: -50px;
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .contact__main {
        min-height: 0;

        .sketh__images {
            width: 100%;
        }

        .contact__sub__section {
            background-color: var(--pink);
            width: 100%;
            position: relative;

            .flex__section {
                padding: 100px 0;
                min-height: 0;

                .contact__heading {
                    h2 {
                        font-size: 200px;
                    }
                }

                .text__section {
                    // padding: 0 100px;

                }

                .back__box {
                    width: 300px;
                    height: 350px;
                }

                .telephone__image {
                    top: 8%;
                    width: 50%;
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .contact__main {
        .contact__sub__section {
            .flex__section {
                .back__box {
                    left: 44px;
                    width: 200px;
                    height: 220px;
                    border-width: 10px;
                }

                .contact__heading {

                    h2 {
                        font-size: 100px;

                    }
                }
                .text__section {
                    // padding: 0 20px;

                }
            }

        }
    }
}

// @media only screen and (min-width:1461px){
//     .contact__main {
//         .contact__sub__section {
//             .flex__section {
//                 .text__section{
//                     padding: 0 200px;
//                 }
//             }
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .contact__main {
//         .contact__sub__section {
//             .flex__section {
//                 .text__section{
//                     padding: 0 300px;
//                 }
//             }
//         }
//     }
// }