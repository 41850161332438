.reviews__main {
    width: 100%;
    min-height: 100vh;

    .reviews__heading {
        h2 {
            font-size: 16rem;
            font-family: var(--anton);
            color: var(--pink);

        }

        .texture__left {
            left: -56px;
            top: 6px;
            width: 150px;
        }

        .texture__right {
            right: -57px;
            bottom: 5px;
            width: 150px;
        }
    }
    .pagination__dot{
        width: 22px;
        height: 22px;
        border: 1px solid var(--white);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            width: 12px;
            height: 12px;
            background-color: var(--white);
            border-radius: 100%;
        }
    }
}

@media only screen and (max-width:1024px) {
    .reviews__main {
        .reviews__heading {
            h2 {
                font-size: 10rem;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .reviews__main {
        height: auto;
    }
}