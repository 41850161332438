@keyframes spin-box {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.contact__hero__main {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding: 0 100px;
    overflow: hidden !important;

    .text__section {
        width: 70%;
        z-index: 0;

        h2 {
            font-size: 252px;
            font-family: var(--anton);
            color: var(--pink);
            text-align: end;
            position: relative;
            margin-bottom: 0 !important;
            line-height: 1;

            span {
                margin-left: -5px;
                line-height: 1;
            }

            .left__texture {
                position: absolute;
                top: -10px;
                left: 30px;
                width: 150px;
            }

            .right__texture {
                position: absolute;
                top: 0;
                right: -50px;
                width: 150px;
                transform: rotate(90deg);
            }
        }

        .about__description {
            font-size: 52px;
            font-family: var(--geologica);
            color: var(--white);
            font-weight: 900;
            text-transform: uppercase;
            text-align: end;
            margin-top: -10px;
            margin-bottom: 0 !important;
            z-index: 2;
        }

    }

    .image__section {
        width: 30%;
        padding-left: 80px;

        .image__box {
            width: 450px;
            height: 550px;
            border: 15px solid var(--gray);
            left: 10px;
            top: 60px;
            padding: 50px;
            position: relative;
            animation: spin-box 13s ease-in-out infinite;

            .image__sub__bos {
                width: 100%;
                height: 100%;
                border: 15px solid var(--gray);
                filter: blur(6px);
            }

            .bulb__section {
                position: absolute;
                top: 0;
                left: -20px;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;

                img {
                    max-width: 770px;
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .contact__hero__main {
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 14rem 0;

        .text__section {
            h2 {
                font-size: 211px;

                .left__texture {
                    top: -42px;
                    left: -72px;
                }

                .right__texture {
                    top: -49px;
                    right: -82px;
                }
            }

            .about__description {
                font-size: 34px;
            }

        }

        .image__section {
            width: 50%;
            padding-left: 80px;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            height: 100%;

            .image__box {
                width: 280px;
                height: 310px;
                padding: 30px;

                .image__sub__bos {
                    width: 100%;
                    height: 100%;
                    border: 15px solid var(--gray);
                    filter: blur(6px);
                }

                .bulb__section {
                    position: absolute;
                    top: 37px;
                    left: -20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    img {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:768px) {
    .contact__hero__main {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 14rem 0;


        .text__section {
            width: 100%;
        }
    }
}

@media only screen and (max-width:525px) {
    .contact__hero__main {
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
        padding: 14rem 0;

        .text__section {
            h2 {
                font-size: 96px;

                .left__texture {
                    top: -42px;
                    left: -72px;
                }

                .right__texture {
                    top: -49px;
                    right: -82px;
                }
            }

            .about__description {
                font-size: 22px;
            }

        }

        .image__section {
            width: 100%;
            padding-left: 80px;
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            height: 100%;

            .image__box {
                width: 250px;
                height: 310px;
                padding: 30px;

                .bulb__section {
                    left: -154px;
                    top: -13px;

                    img {
                        max-width: 320px;
                    }
                }
            }
        }
    }
}


// @media only screen and (min-width:1280px){
//     .contact__hero__main{
//         .text__section{
//             h2{
//                 font-size: 340px;
//             }
//         }
//     }
// }

// @media only screen and (min-width:1461px){
//     .contact__hero__main{
//         padding: 0 200px;
//         .text__section{
//             h2{
//                 font-size: 340px;
//             }
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .contact__hero__main{
//         padding: 0 300px;
//         .text__section{
//             h2{
//                 font-size: 350px;
//             }
//         }
//     }
// }