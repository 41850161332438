@keyframes rightToLeftRightSide {
    0% {
        transform: rotate(332deg) translateX(-50%);
    }

    100% {
        transform: rotate(332deg) translateX(-100%);
    }
}
@keyframes leftToRightLeftSide {
    0% {
        transform: rotate(-332deg) translateX(-100%);
    }

    100% {
        transform: rotate(-332deg) translateX(-50%);
    }
}
@keyframes rightToLeftLeftSide {
    0% {
        transform: rotate(-332deg) translateX(-50%);
    }

    100% {
        transform: rotate(-332deg) translateX(-100%);
    }
}

@keyframes leftToRightRightSide {
    0% {
        transform: rotate(332deg) translateX(-100%);
    }

    100% {
        transform: rotate(332deg) translateX(-50%);
    }
}




.lines__main {
    transform-origin: 50% 50%;
    transform: rotate(25deg) translateX(-10%);
    animation-duration: 100s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    font-family: var(--gobold);
    font-size: 5.5rem;

    span {
        color: var(--white);
        text-transform: uppercase;
        white-space: nowrap;
        margin-left: 20px;
    }
    &.left__side__ltr{
        animation-name: leftToRightLeftSide;
    }
    &.right__side__rtl{
        animation-name: rightToLeftRightSide;
    }
    &.right__side__ltr{
        animation-name: leftToRightRightSide;
    }
    &.left__side__rtl{
        animation-name: rightToLeftLeftSide;
    }
}
@media only screen and (max-width:525px){
    .lines__main{
        font-size: 3rem !important;
    }
}