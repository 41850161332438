.portfolio__card__section__main {
  overflow: hidden;
  position: relative;

  .sketh__images {
    width: 100%;

    &.bottom__sketch {
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  }

  .container {
    min-height: 1140px;
    .bracket {
      margin-right: 20px;
      font-size: 30px;
      font-family: var(--anton);

      .category_btn {
        border: none;
        outline: none;
        padding: 0;
        background-color: transparent;
        font-size: 52px;
        font-family: var(--anton);
        color: var(--black);
        margin-top: 10px;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 20px;
        text-transform: uppercase;
        cursor: none;
        text-decoration: none;

        &:hover {
          text-decoration: line-through 8px red;
        }

        &.sml__btn {
          font-size: 24px;
          color: var(--white);

          &:hover {
            text-decoration: line-through 4px red;
          }
        }
      }
    }
  }

  .about__sub__section {
    background-color: var(--pink);
    width: 100%;
    padding-bottom: 100px;
  }

  .line__section {
    margin-top: -250px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .portfolio__card__section {
    .portfolio__card__main {
      width: 100%;
      max-height: 350px;
      transition: all 0.3s ease;
      overflow: hidden;

      img {
        object-fit: cover;
        border-radius: 10px;
        max-height: 350px;
      }

      .hoverd__div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000;
        opacity: 0.4;
        z-index: 2;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .portfolio__card__section__main {
    .about__sub__section {
    }
  }
}

@media only screen and (max-width: 525px) {
  .portfolio__card__section__main {
    .about__sub__section {
    }
  }
}
