@font-face {
    font-family: "GeoLogica";
    src: url("./static/Geologica-Bold.ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "GeoLogica";
    src: url("./static/Geologica-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}