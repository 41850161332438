.app__development__heading {
    position: relative;

    .texture__right {
        width: 100px;
        right: -57px;
        bottom: -26px;
    }

    .texture__left {
        width: 100px;
        left: -56px;
        top: -29px;
    }

    h2 {
        font-size: 3.2rem;
        font-family: var(--gobold);
        letter-spacing: -.2rem;
        text-transform: uppercase;
        color: var(--white);
        margin-bottom: 0 !important;

        &.color__black {
            color: var(--black);
        }
    }
}