@import "bootstrap/dist/css/bootstrap.min.css";
@import "antd/dist/reset.css";
@import url("./theme/fonts/GoBold/style.css");
@import url("./theme/fonts/GeoLogica/style.css");
@import url("./theme/fonts/Anton/style.css");

*{
    cursor: none;
}

:root {
    --black: #191919;
    --gobold: "GoBold";
    --geologica: "GeoLogica";
    --pink: rgb(201, 49, 114);
    --white: #fff;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-skew-x: -12deg;
    --anton:"Anton";
    --geologica-hollow:"GoBold Hollow";
    --gray:#c7c7c7;
    --yellow:#ffb300;
}