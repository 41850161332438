.home__reviews__swipper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  .swiper-slide {
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 355px !important;
    cursor: url("../../../assets/images/reviewslidercursorr.png"), auto;

    * {
      cursor: url("../../../assets/images/reviewslidercursorr.png"), auto;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .carousel__card {
      width: 50%;
      display: flex;
      max-width: 1240px;

      .slider__text__section {
        background-image: url("../../../assets/images/reviewbg.png");
        background-repeat: no-repeat;
        background-size: 150% auto;
        background-position: bottom left;
        background-color: #F2F2F2;
        padding: 3rem 1.5rem 1.5rem;

        .stars__section {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 19px;
          }
        }

        .pera__section {
          height: 150px;
          margin-top: 20px;
          overflow-y: auto;
          font-size: 17px;
          font-family: var(--geologica);
          color: #191919;

          &::-webkit-scrollbar {
            width: 9px;
            background-color: #191919;

            &-thumb {
              background-color: #ffb300;
            }

          }
        }
      }

      .name__section {
        color: #191919;
        margin-top: 2rem;

        h2 {
          font-family: var(--anton);
          font-size: 37px;
        }

        p {
          font-size: 12px;
          font-family: var(--geologica);
          font-weight: 400;
        }
      }
    }
  }
}


@media only screen and (max-width:1024px) {
  .swiper {
    .swiper-slide {
      .carousel__card {
        width: 75%;
      }
    }
  }
}


@media only screen and (max-width:525px) {
  .swiper {
    .swiper-slide {
      height: auto !important;

      .carousel__card {
        flex-direction: column-reverse;
        width: 85% !important;

        .slider__text__section {
          width: 100%;

          .pera__section {
            height: auto;
          }

          .stars__section {
            justify-content: center;
          }
        }

        .slider__img__section {
          width: 100%;
        }
      }
    }
  }
}