@font-face {
    font-family: "GoBold";
    src: url("Gobold\ Bold.otf");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "GoBold Hollow";
    src: url("Gobold\ Hollow.otf");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "GoBold";
    font-weight: 500;
    src: url("./Gobold\ Regular.otf");
}