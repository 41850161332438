.hero__main {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;

    .hero__sub__main {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;


        .text__section {
            width: 60%;
            z-index: 1;

            h1 {
                font-family: var(--gobold);
                font-size: 72px;
                line-height: 1.01;
                color: var(--pink);
                text-transform: uppercase;
                margin-bottom: 1rem;

                span {
                    background-color: transparent;
                    color: var(--white);
                }
            }

            .hero__description {
                font-family: var(--geologica);
                font-size: 18px;
                font-weight: 600;
                color: var(--white);
                line-height: 29px;
                max-width: 540px;
            }

            .btn__section {
                margin-top: 2.6rem;
            }
        }

        .hero__image {
            width: 40%;

            img {
                max-width: 100%;
            }
        }

        .hero__icon {
            position: absolute;
            top: 2rem;
            left: -7rem;
        }
    }

}

@media only screen and (max-width:1024px) {
    .hero__main {

        .hero__sub__main {
            display: block;

            .text__section {
                width: 100%;
                z-index: 1;

                h1 {
                    font-size: 42px;
                }
            }

            .hero__image {
                width: 100%;

                img {
                    max-width: 100%;
                }
            }
        }

    }
}

@media only screen and (max-width:768px) {
    .hero__main {
        .hero__sub__main {
            display: block;

            .text__section {
                width: 100%;
                z-index: 1;

                h1 {
                    font-size: 42px;
                }

                .hero__description {
                    font-family: var(--geologica);
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--white);
                    line-height: 29px;
                }

                .btn__section {
                    margin-top: 2.6rem;
                }
            }

            .hero__image {
                width: 100%;

                img {
                    max-width: 100%;
                }
            }

            .hero__icon {
                position: absolute;
                top: 2rem;
                left: -7rem;
            }
        }

    }
}
