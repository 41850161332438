.app__development__main {
    width: 100%;
    padding: 2rem 0;

    .text__heading {
        font-size: 50px;
        font-family: var(--gobold);
        color: var(--white);

        span {
            color: var(--pink);
        }
    }

    .text__description {
        font-family: var(--geologica);
        font-size: 16px;
        color: var(--white);
    }

    .service__card {
        h4 {
            font-size: 22px;
            font-family: var(--gobold);
            color: var(--pink);
        }

        p {
            font-family: var(--geologica);
            color: var(--white);
            font-size: 13px;
        }
    }

    .img__section {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
            width: 70%;
        }
    }

    .content__section {
        // padding: 0 100px;
        width: 100%;
        margin-top: 50px;
        max-height: 500px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
        }

        .content__card {
            min-height: 500px;
            display: flex;
        }

    }

}

@media only screen and (max-width:768px) {
    .app__development__main {
        .img__section {
            justify-content: center;
        }

        .content__section {
            // padding: 0 20px;

            .content__card {
                margin-top: 40px;
                display: block;
            }

        }

    }
}

@media only screen and (max-width:525px) {
    .app__development__main {
        overflow: hidden;
        .heading__section {
            // padding: 0 20px;

            h2 {
                width: fit-content;
            }
        }

        .img__section {
            justify-content: center;
        }

        .content__section {
            .content__card {
                padding: 0 20px;
                margin-top: 40px;
                display: block;
            }

        }

    }
}

// @media only screen and (min-width:1461px){
//     .app__development__main{
//         .content__section{
//             padding: 0 200px;
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .app__development__main{
//         .content__section{
//             padding: 0 300px;
//         }
//     }
// }