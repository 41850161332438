@keyframes rightToLeft {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes moveInAir {
    0% {
        transform: translateY(-40px);
    }

    50% {
        transform: translateY(40px);
    }

    100% {
        transform: translateY(-40px);
    }
}


.service__details__main {
    width: 100%;
    overflow: hidden;
    padding: 5rem 0;

    .halfWidth {
        width: 50%;
    }

    .service__detail__heading {
        font-size: 64px;
        font-family: var(--geologica);
        color: var(--yellow);
        text-align: center;
        font-weight: 900;
        letter-spacing: -4px;
    }

    .details__sub__container {

        .image__section {
            img {
                max-width: 90%;
            }

            &.bulb__section {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                img {
                    max-height: 400px;
                }
            }

            &.designerGirl {
                .girlImg {
                    animation: moveInAir 12s ease-in-out 1s infinite
                }
            }
        }

        .text__section {
            p {
                font-family: var(--geologica);
                color: var(--white);
                font-size: 20px;
            }

            h2 {
                font-size: 60px;
                text-transform: uppercase;
                font-family: var(--geologica);
                color: var(--yellow);
                font-weight: 900;
                letter-spacing: -4px;
                line-height: 1;
            }

            .list__item {
                font-size: 20px;
                color: var(--white);
                font-family: var(--geologica);
            }
        }

        &.mt-4 {
            margin-top: 160px !important;
        }
    }

    .cloud__container {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(0);
        animation-duration: 83s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: .5s;

        &.rtl {
            animation-name: rightToLeft;
        }

        &.ltr {
            animation-name: leftToRight;
        }

        &.bot {
            top: inherit;
            bottom: -15px;
        }
    }
}

@media only screen and (max-width:1024px) {
    .service__details__main {
        .halfWidth {
            width: 100%;
        }


        .details__sub__container {
            .image__section {
                img {
                    max-width: 90%;
                }

                &.bulb__section {
                    justify-content: center;

                    img {
                        max-height: 400px;
                    }
                }

                &.designerGirl {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}


@media only screen and (max-width:525px) {
    .service__details__main {
        .details__sub__container {

            .text__section {
                p {
                    font-size: 16px;
                }

                h2 {
                    font-size: 38px;
                    letter-spacing: -1px;

                }

                .list__item {
                    font-size: 16px;
                }
            }
        }

        .service__detail__heading {
            font-size: 38px;
            letter-spacing: -1px;
        }

    }
}
