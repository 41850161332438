.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 65px;
  height: 65px;
  border: 2px solid var(--white);
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999999999999;
  pointer-events: none;

  &.hidden {
    display: none;
  }

  &.fill {
    background-color: red;
    border-color: red;
    opacity: 0.6;
  }
}

.dot {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999999999999;
  pointer-events: none;

  &.fill,
  &.hidden {
    display: none;
  }
}
