.root {
    background-color: var(--black);

    .home__main {
        padding: 0px 200px;
    }
}

.full__image {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .95);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    img {
        width: 100%;
        height: 100vh;
        object-fit: contain;
    }

    button {
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        position: absolute;
        right: 20px;
        top: 20px;

        &:hover {
            animation: rotate 1s;
            .close__icon__2{
                color: #ffb300;
            }
        }

        .close__icon__2 {
            color: white;

            &:hover {
                color: #ffb300;
            }
        }
    }
}