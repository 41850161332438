.contact__form__main {
    overflow: hidden;
    position: relative;
    .sketh__images {
        width: 100%;
        &.bottom__sketch{
            position: absolute;
            bottom: 0;
            z-index: 3;
        }
    }

    .about__sub__section {
        background-color: var(--pink);
        width: 100%;

        .tab__section{
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 50px;
            flex-wrap: wrap;
        }

        .flex__section {
            padding: 1rem 0;
            display: flex;

            .images__area {
                width: 50%;
                display: flex;
                img{
                    max-width: 350px;
                    z-index: 1;
                }
            }

            .about__area {
                width: 50%;

                p{
                    font-size: 17px;
                    font-family: var(--geologica);
                    font-weight: 600;
                    line-height: 25px;
                }
                .links__section{
                    a{
                        text-decoration: none;
                        color: var(--black);
                        font-size: 17px;
                        font-weight: 600;
                        font-family: var(--geologica);
                        &:hover{
                            color: var(--yellow);
                            cursor: pointer;
                        }
                    }
                    .icons__section {
                        .social__icon {
                        width: 22px;
                        height: 22px;
                        transition: all 0.3s ease;
    
                        &:hover {
                            width: 36px;
                            height: 36px;
                            color: var(--yellow);
                        }
                    }
                }
                    }
            }
            .form__area{
                width: 60%;
                h3{
                    font-family: var(--anton);
                    font-size: 56px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                .input__section{
                    width: 100%;
                    input{
                        border: none;
                        padding: 7px 10px;
                        font-family: var(--geologica);
                        font-size: 13px;
                        &:focus,&:active{
                            outline: none;
                            border: none;
                        }
                    }
                    &.width50{
                        width: calc(50% - 10px);
                    }
                }
                p{
                    font-size: 14px;
                    font-weight: 600;
                    font-family: var(--geologica);
                    color: var(--black);
                }
            }
            .text__area{
                width: 40%;
                padding: 30px;
                p{
                    font-size: 18px;
                    font-family: var(--geologica);
                    font-weight: 600;
                    margin-bottom: 20px !important;
                }
                a{
                    text-decoration: none;
                    color: var(--black);
                    font-size: 17px;
                    font-weight: 600;
                    font-family: var(--geologica);
                    &:hover{
                        color: var(--yellow);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .contact__form__main {
        .about__sub__section {

            .flex__section {
                padding: 1rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                }
                .form__area{
                    width: 100%;
                }
                .text__area{
                    width: 100%;
                    padding: 0;
                    margin-top: 30px;
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .contact__form__main {
        .about__sub__section {

            .flex__section {
                padding: 1rem 20px;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                    .about__images {
                        img {
                           max-height: 300px;
                        }
                    }
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            span {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:1461px){
    .contact__form__main{
        .about__sub__section{
            .flex__section{
                padding: 1rem 0;
                .images__area{
                    justify-content: center;
                }
            }
        }
    }
}