.about__main {
    .sketh__images {
        width: 100%;
    }

    .about__sub__section {
        background-color: var(--pink);
        width: 100%;

        .flex__section {
            padding: 5rem 0;
            display: flex;

            .images__area {
                width: 50%;
                z-index: 2;

                .about__images {
                    img {
                        filter: grayscale(100%);
                        cursor: url("../../assets/images/eye.png"), auto;
                        max-height: 585px;

                        &:hover {
                            filter: grayscale(0);
                        }
                    }
                }
            }

            .about__area {
                width: 50%;
                min-height: 80vh;

                .about__text {
                    h2 {
                        text-align: right;

                        div {
                            font-size: 240px;
                            font-family: var(--anton);
                            font-weight: 600;
                        }
                    }

                    p {
                        font-size: 17px;
                        line-height: 1.25;
                        font-weight: 600;
                        font-family: var(--geologica);
                        max-width: 75%;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width:1024px) {
    .about__main {
        .about__sub__section {

            .flex__section {
                padding: 5rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            justify-content: center;

                            div {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .about__main {
        .about__sub__section {

            .flex__section {
                padding: 5rem 0;
                display: block;

                .images__area {
                    width: 100%;
                    justify-content: center;
                    .about__images {
                        img {
                           max-height: 300px;
                        }
                    }
                }

                .about__area {
                    width: 100%;
                    min-height: 0;

                    .about__text {
                        h2 {
                            text-align: center;

                            div {
                                font-size: 150px;
                                margin-left: -5px;
                            }
                        }

                        p {
                            font-size: 13px;
                            max-width: 80%;
                            text-align: center;
                        }
                        .btn__section{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
    .marginOnMob{
        margin-left: -3rem !important;
    }
}
@media only screen and (min-width:1400px){
    .about__main{
        .about__sub__section{
            .flex__section{
                .images__area{
                    .about__images{
                        img{
                            max-height: 665px;
                        }
                    }
                }
            }
        }
    }
}