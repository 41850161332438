@keyframes spin-box {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.portfolio__hero__main {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0 100px;
    overflow: hidden;

    .text__section {
        width: 100%;

        h2 {
            font-size: 200px;
            font-family: var(--anton);
            color: var(--pink);
            justify-content: center;
            position: relative;
            margin-bottom: 0 !important;
            line-height: 1;

            div {
                margin-left: -5px;
                line-height: 1;
            }

            .left__texture {
                position: absolute;
                top: -10px;
                left: 30px;
                width: 150px;
            }

            .right__texture {
                position: absolute;
                top: 0;
                right: 32px;
                width: 150px;
                transform: rotate(90deg);
                z-index: 99;
            }
        }

        .about__description {
            font-size: 52px;
            font-family: var(--geologica);
            color: var(--white);
            font-weight: 900;
            text-transform: uppercase;
            text-align: center;
            margin-top: -10px;
            margin-bottom: 0 !important;
        }

        .btn__section {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

    }

}

@media only screen and (max-width:768px) {
    .portfolio__hero__main {
        flex-direction: row-reverse;
        // padding: 0 30px;


        .text__section {

            h2 {
                font-size: 180px;
                justify-content: start;

                .left__texture {
                    top: -35px;
                    left: -63px;
                }

                .right__texture {
                    top: -16px;
                    right: -87px;
                }
            }

            .about__description {
                font-size: 32px;
                text-align: start;
            }

            .btn__section {
                justify-content: flex-start;
            }

        }
    }
}


@media only screen and (max-width:525px) {
    .portfolio__hero__main {
        flex-direction: row-reverse;
        // padding: 0 30px;
        min-height: 65vh;


        .text__section {

            h2 {
                font-size: 125px;
                text-align: start;

                .left__texture {
                    top: -35px;
                    left: -63px;
                }

                .right__texture {
                    top: -16px;
                    right: -87px;
                }
            }

            .about__description {
                font-size: 21px;
                margin-top: 6px;
                text-align: start;
            }

            .btn__section {
                margin-top: 6px;
            }

        }
    }
}

@media only screen and (min-width:1280px) {
    .portfolio__hero__main {
        .text__section {
            h2 {
                font-size: 280px;
            }
        }
    }
}