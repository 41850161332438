.slider__main__div {
  cursor: url("../../../assets/images/eye.png"), auto !important;

  .portfolio__swipper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    cursor: url("../../../assets/images/eye.png"), auto !important;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .portfolio__swipper {
    .swiper-slide {
      img {
        cursor: url("../../../assets/images/eye.png"), auto !important;
      }

      &.swiper-slide-active {
        .upper__div {
          height: 0;
          transition: height 0.3s ease-in-out;
          cursor: url("../../../assets/images/eye.png"), auto !important;
        }

        &:hover {
          .upper__div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #0000009e;
            opacity: .6;
          }
        }
      }
    }
  }
}
.cutsom__btn{
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 65px;
  height: 65px;
  border-radius: 100%;
  
  img{
    width: 60px;
  }
  &.custom-next-btn{
    transform: rotate(180deg); 
  }
  &:hover{
    background-color: var(--pink);
  }
}