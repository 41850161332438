@keyframes spin-box {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-8deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.about__hero__main {
  width: 100%;
  min-height: 90vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  .text__section {
    width: 67%;

    h2 {
      font-size: 252px;
      font-family: var(--anton);
      color: var(--pink);
      justify-content: flex-end;
      position: relative;
      margin-bottom: 0 !important;
      margin-top: 20px;
      line-height: 1;

      div {
        margin-left: -5px;
        line-height: 1;
      }

      .left__texture {
        position: absolute;
        top: -10px;
        left: 30px;
        width: 150px;
      }

      .right__texture {
        position: absolute;
        top: 0;
        right: -50px;
        width: 150px;
        transform: rotate(90deg);
      }
    }

    .about__description {
      font-size: 52px;
      font-family: var(--geologica);
      color: var(--white);
      font-weight: 900;
      text-transform: uppercase;
      text-align: end;
      margin-top: -10px;
      margin-bottom: 0 !important;
    }

    .btn__section {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .image__section {
    width: 30%;
    padding-left: 80px;

    .image__box {
      width: 55vh;
      height: 70vh;
      border: 15px solid var(--gray);
      left: 10px;
      top: 10px;
      padding: 30px;
      position: relative;
      animation: spin-box 13s ease-in-out infinite;

      .image__sub__bos {
        width: 100%;
        height: 100%;
        border: 15px solid var(--gray);
        filter: blur(6px);
      }

      .bulb__section {
        position: absolute;
        top: 59px;
        left: -20px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        img {
          max-width: 250px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .about__hero__main {
    flex-direction: row-reverse;

    .text__section {
      width: 60%;

      h2 {
        font-size: 180px;
        justify-content: start;

        .left__texture {
          top: -35px;
          left: -63px;
        }

        .right__texture {
          top: -16px;
          right: -87px;
        }
      }

      .about__description {
        font-size: 32px;
        text-align: start;
      }

      .btn__section {
        justify-content: flex-start;
      }
    }

    .image__section {
      width: 30%;
      padding-left: 80px;

      .image__box {
        width: 230px;
        height: 300px;
        left: -1px;
        z-index: 1;
        border-width: 12px;

        .image__sub__bos {
          width: 100%;
          height: 100%;
          border: 15px solid var(--gray);
          filter: blur(6px);
        }

        .bulb__section {
          top: 93px;
          left: -257px;
          z-index: 2;

          img {
            max-width: 250px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 525px) {
  .about__hero__main {
    flex-direction: row-reverse;
    min-height: 65vh;

    .text__section {
      width: 60%;

      h2 {
        font-size: 125px;
        text-align: start;

        .left__texture {
          top: -35px;
          left: -63px;
        }

        .right__texture {
          top: -16px;
          right: -87px;
        }
      }

      .about__description {
        font-size: 21px;
        margin-top: 6px;
        text-align: start;
      }

      .btn__section {
        margin-top: 6px;
      }
    }

    .image__section {
      .image__box {
        width: 230px;
        height: 300px;
        left: -40px;

        .bulb__section {
          top: 30px;
          left: -40px;

          img {
            max-width: 150px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .about__hero__main {
    .text__section {
      h2 {
        font-size: 252px;
      }
    }
  }
}
