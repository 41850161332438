.faq__main {
    // padding: 12px 50px 20px 50px;

    .faq__heading {
        text-align: center;
        font-family: var(--gobold);
        padding-bottom: 2.5rem;
        color: var(--white);
        font-size: 4.5rem;
    }

    .accordian__custom {
        border: none !important;
        border-radius: 0 !important;

        .ant-collapse-item {
            border-radius: 0 !important;

            .ant-collapse-header {
                border-radius: 0 !important;

                .ant-collapse-header-text {
                    font-size: 18px;
                    font-family: var(--gobold);
                    color: white;
                }
            }

            .ant-collapse-content {
                border-top: none !important;

                .ant-collapse-content-box {
                    padding-top: 0 !important;

                    p {
                        color: var(--pink);
                        font-family: var(--geologica);
                        font-size: 14px;
                    }

                    h2 {
                        font-size: 18px;
                        font-family: var(--gobold);
                        color: var(--pink);
                    }
                }
            }

            &.ant-collapse-item-active {
                background-color: var(--white) !important;

                .ant-collapse-header-text {
                    color: var(--pink);
                }
            }
        }

        .collapse__btn {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 0;
        }
    }
}

// @media only screen and (max-width:525px){
//     .faq__main{
//         padding: 0 20px;
//     }
// }
// @media only screen and (min-width:1461px){
//     .faq__main{
//         padding: 0 200px;
//     }
// }

// @media only screen and (min-width:1700px){
//     .faq__main{
//         padding: 0 300px;
//     }
// }