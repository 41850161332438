.footer__main {
    width: 100%;

    .footer__sub__section {
        // padding: 0 100px;

        .logo__section {
            padding-top: 6rem;
            display: flex;
            align-items: center;

            img {
                width: 300px;
            }
        }
    }

    .texture__section {
        img {
            width: 100%;
        }
    }

    .list__section {
        h3 {
            font-size: 42px;
            font-family: var(--gobold);
            color: var(--white);
            text-transform: uppercase;
        }

        .list__item {
            font-size: 20px;
            font-family: var(--gobold);
            color: var(--white);
            text-transform: uppercase;
            text-decoration: none;

            div {
                margin-top: 5px;
            }

            &:hover {
                text-decoration: line-through 3px red;
            }
        }
    }

    .social__section {
        h3 {
            font-size: 42px;
            font-family: var(--gobold);
            color: var(--white);
            text-transform: uppercase;
        }

        .list__item {
            font-size: 20px;
            font-family: var(--gobold);
            color: var(--white);
            text-transform: uppercase;
            text-decoration: none;
        }

        .icons__section {
            height: 60px;

            .social__icon {
                width: 24px;
                height: 24px;
                transition: all 0.3s ease;

                &:hover {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .copy__right__section {
        background-color: var(--pink);
        padding-bottom: 15px;

        h3 {
            font-family: var(--gobold);
            font-size: 23px;
            color: var(--white);
            text-align: center;
        }

        p {
            font-size: 12px;
            font-family: var(--anton);
            text-align: center;
        }
    }
}

@media only screen and (max-width:768px) {
    .footer__main {
        .footer__sub__section {
            // padding: 0 30px;

            .logo__section {
                img {
                    width: 255px;
                }
            }
        }

        .list__section {
            .list__item {
                font-size: 18px;
            }
        }

        .social__section {
            h3 {
                font-size: 30px;
            }

            .list__item {
                font-size: 18px;
            }

            .icons__section {
                .social__icon {
                    width: 22px;
                    height: 22px;

                    &:hover {
                        width: 36px;
                        height: 36px;
                    }
                }
            }
        }

        .copy__right__section {
            h3 {
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .footer__main {
        .footer__sub__section {
            // padding: 0 30px;

            .logo__section {
                display: flex;
                justify-content: center;
                padding-top: 30px !important;
                img {
                    width: 180px;
                }
            }
        }

        .list__section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
            .list__item {
                font-size: 18px;
                width: fit-content;
            }
        }

        .social__section {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            h3 {
                font-size: 30px;
            }

            .list__item {
                font-size: 18px;
                text-align: center;
            }

            .icons__section {
                .social__icon {
                    width: 22px;
                    height: 22px;

                    &:hover {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            div {
                text-align: center;
                justify-content: center;
            }
        }

        .copy__right__section {
            h3 {
                font-size: 18px;
            }
        }
    }
}

// @media only screen and (min-width:1461px){
//     .footer__main{
//             .footer__sub__section{
//                 padding: 0 200px;
//             }
//     }
// }

// @media only screen and (min-width:1700px){
//     .footer__main{
//             .footer__sub__section{
//                 padding: 0 300px;
//         }
//     }
// }