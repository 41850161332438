@keyframes fromLefttoRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.overflow__hid{
    overflow: hidden;
}
.impress__main__2 {
    font-family: var(--gobold);
    position: relative;
    transform: translate(-100%);
    &.tranl{
        animation-name: fromLefttoRight;
        animation-duration: .75s;
        transform: translate(0%);
    }

    .impress__img {
        width: 100%;
        position: absolute;
        right: 21rem;
        height: 100%;
        top: 0;
        transform: rotate(180deg);
    }

    .impress__brush {
        position: absolute;
        top: 0;
        filter: drop-shadow(8px 4px 14px black);
        height: 100%;
        width: 60%;
        left: calc(100% - 23rem);
        transform: rotate(180deg);
    }

    .impress__text__section {
        position: relative;
        padding: 8rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .w-30 {
            width: 30%;
            &.right__text__section{
                padding: 0 20px;
                .right__text {
                    font-size: 14px;
                    font-family: var(--geologica);
                }
            }
        }

        .impress__heading__section {
            width: 40%;

            h2 {
                font-size: 45px;
                font-family: var(--gobold);
                text-align: center;

                span {
                    font-family: var(--geologica-hollow);
                }
            }
            .arrow__images{
                width: 30px;
            }
        }
    }
}
@media only screen and (max-width:1024px) {
    .impress__main__2 {
        .impress__img {
            right: 15rem;
        }

        .impress__brush {
            left: calc(100% - 18rem);
        }

        .impress__text__section {
            padding: 5rem 0;
            justify-content: center;

            .w-30 {
                width: 30%;

                &.right__text__section {
                    display: none;
                }
            }

        }
    }
}
@media only screen and (max-width:768px) {
    .impress__main__2 {
        .impress__img {
            right: 10rem;
        }

        .impress__brush {
            left: calc(100% - 13rem);
        }

        .impress__text__section {
            padding: 5rem 0;
            justify-content: center;
            .impress__heading__section{
                width: 50%;
                h2{
                    font-size: 30px;
                }
            }

            .w-30 {
                width: 20%;

                &.right__text__section {
                    display: none;
                }
            }

        }
    }
}
@media only screen and (max-width:525px) {
    .impress__main__2 {
        .impress__img {
            left: -10%;
            width: 110%;
        }

        .impress__brush {
            display: none;
        }

        .impress__text__section {
            padding: 5rem 0;
            justify-content: center;
                        .w-30 {
                display: none;
            }

        }
    }
}

// @media only screen and (min-width:1461px){
//     .impress__main__2 {
//         .impress__text__section {
//             padding: 8rem 200px;
//         }
//     }
// }

// @media only screen and (min-width:1700px){
//     .impress__main__2 {
//         .impress__text__section {
//             padding: 8rem 300px;
//         }
//     }
// }