.portfolio__main{
    padding: 0;
    font-family: var(--gobold);
    min-height: 70vh;
    .portfolio__heading{
        font-size: 78px;
        font-weight: 400;
        color: var(--white);
        text-align: center;
        span{
            color: var(--pink);
        }
    }
    .portfolio__description{
        font-size: 23px;
        font-weight: 400;
        color: var(--white);
        text-align: center;
    }   
}

@media only screen and (max-width:525px){
    .portfolio__main{
        padding: 0;
        font-family: var(--gobold);
        .portfolio__heading{
            font-size: 40px;
            word-break: break-all;
            font-weight: 400;
            color: var(--white);
            text-align: center;
            span{
                color: var(--pink);
            }
        }
        .portfolio__description{
            font-size: 20px;
            font-weight: 400;
            color: var(--white);
            text-align: center;
        }   
    }
}