@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
    }
}

@keyframes bird {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(-8deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


.navbar__main {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    background: linear-gradient(180deg, #000, rgba(67, 67, 67, 0) 74%, rgba(84, 84, 84, 0));

    .menu__btn {
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0;

        &:hover {
            animation: rotate .5s;
            transform: rotate(90deg);
        }
    }
}

.drawer__main {
    background: var(--black) !important;
    background-image: url("../../assets/images/menu-background.png") !important;

    .flex__section {
        display: flex;
    }
    .drawer__close__btn{
        position: relative;
    }

    .services__btns {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .close__btn {
        border: none;
        outline: none;
        background-color: transparent;

        &:hover {
            animation: rotate 1s;
        }

        .close__icon {
            color: var(--white);
            width: 50px;
            height: 50px;

            &:hover {
                color: var(--black);
            }
        }
    }

    .drawer__footer {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;

        .header__footer {
            font-size: 17px;
            font-family: var(--gobold);
            color: var(--white);
            margin-top: -50px;
            padding-bottom: 20px;

            .info__icons {
                width: 18px;
                height: 18px;
                color: var(--white);
            }

            &:hover {
                color: #ffb300;

                .info__icons {
                    color: #ffb300;
                }
            }
        }
        img{
            width: 100%;
            height: 117px;
            object-fit: cover;
        }
    }

    .menu__section {
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        .menu__btns__section {
            min-width: 300px;
            z-index: 12;

            .menu__btn {
                border: none;
                outline: none;
                padding: 0;
                background-color: transparent;
                font-size: 52px;
                font-family: var(--anton);
                color: var(--black);
                margin-top: 10px;
                text-transform: uppercase;
                cursor: none;
                text-decoration: none;

                &:hover {
                    text-decoration: line-through 8px red;
                }

                &.sml__btn {
                    font-size: 20px;
                    color: var(--white);

                    &:hover {
                        text-decoration: line-through 4px red;
                    }
                }
            }
        }

        .hero__icon {
            position: absolute;
            left: -180px;
            top: 10px;
        }
    }

    .bird__img__section {
        width: 45%;
        display: flex;
        justify-content: center;

        .bird__box {
            transform: rotate(-4deg);
            height: 350px;
            width: 300px;
            border: 12px solid #c7c7c7;
        }

        .bird__img {
            position: absolute;
            bottom: -50px;
            width: 300px;
            right: -50px;
            animation: bird 13s ease-in-out infinite;
        }
    }
}

@media only screen and (max-width:768px) {
    .navbar__main {
        padding: 20px 30px;
    }
}

@media only screen and (max-width:525px) {
    .drawer__main {
        background-position: right !important;
        background-size: 200% 100% !important;

        .flex__section {
            flex-direction: column;
            align-items: center;
        }

        .menu__section {
            width: 100%;
            align-items: center;

            .menu__btn {
                font-size: 32px;
            }

            .hero__icon {
                left: -66px;
                top: 35px;
                max-width: 100px;
            }
        }

        .drawer__footer {
            display: none;
        }

        .bird__img__section {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;

            .bird__box {
                height: 280px;
                width: 220px;
            }

            .bird__img {
                top: 20px;
                width: 150px;
                right: -50px;
            }
        }
    }
}

@media only screen and (max-height:720px) {
    .drawer__main {
        background: var(--black) !important;
        background-image: url("../../assets/images/menu-background.png") !important;
        .flex__section {
            margin-top: -50px;
        }
        .menu__section {
            .menu__btns__section {

                .menu__btn {
                    font-size: 36px;
                }
            }
        }
    }
}