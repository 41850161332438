.main {
  width: 100%;
  overflow: hidden;
  position: relative;

  .sketh__images {
    width: 100%;
    &.bottom__sketch {
      position: absolute;
      bottom: 0;
      z-index: 3;
    }
  }

  .policies {
    background-color: var(--pink);
    min-height: 100px;
    font-family: var(--anton);
    font-weight: 600;
    justify-content: center;
    padding-bottom: 70px;
    .policy {
      width: 85%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.22);
      .number,
      .policy_details {
        color: var(--white);
      }
      .name {
        color: var(--black);
      }
      .policy_details {
        font-weight: 300;
        font-family: var(--geologica);
      }
    }
  }
}
