.about__review__swipper {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;

  .swiper-slide {
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 320px !important;
    cursor: url("../../../../assets/images/reviewslidercursorr.png"), auto;

    * {
      cursor: url("../../../../assets/images/reviewslidercursorr.png"), auto;
    }

    .carousel__card {
      width: 70%;
      background-image: url("../../../../assets/images/reviewbg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom left;
      display: flex;
      position: relative;
      max-width: 1240px;

      .card__img__section {
        display: flex;
        align-items: center;
        justify-content: center;

        .main__img {
          width: 150px;
          height: auto;
          z-index: 5;
        }

        .sketch__section {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      .card__text__section {
        background-color: #F2F2F2;
        padding: 3rem 1.5rem 1.5rem;
        display: flex;
        align-items: center;

        .name {
          font-family: var(--geologica);
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0 !important;
        }

        .text {
          margin-bottom: 0 !important;
          font-size: 20px;
          margin-top: 20px;
        }

        .designation {
          margin-bottom: 0 !important;
          font-size: 16px;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
    }
  }
}


@media only screen and (max-width:1024px) {
  .swiper {
    .swiper-slide {
      .carousel__card {
        width: 75%;
      }
    }
  }
}

@media only screen and (max-width:525px) {
  .about__review__swipper {
    width: 100%;

    .swiper-slide {
      height: auto !important;

      .carousel__card {
        display: block !important;

        .card__img__section {
          width: 100% !important;
          margin-top: -64px;

          .sketch__section {
            width: 100% !important;
          }
        }

        .card__text__section {
          width: 100% !important;
        }
      }
    }
  }

}