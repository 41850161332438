.pricing__section__main {
    overflow: hidden;
    position: relative;

    .sketh__images {
        width: 100%;

        &.bottom__sketch {
            position: absolute;
            bottom: 0;
            z-index: 3;
        }
    }

    .about__sub__section {
        background-color: var(--pink);
        width: 100%;

        .portfolio__description{
            width: 50%;
            font-family: var(--geologica);
        }

        .tab__section {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 50px;
            flex-wrap: wrap;
        }

        .pricing__card__container {
            gap: 20px;

            .pricing__card {
                // border: 1px solid var(--white);
                padding: 50px !important;
                width: calc(33% - 20px);
                border-radius: 5px;
                background-color: var(--black);
                color: var(--white);
                height: 600px;
                overflow-y: auto;

                &.small{
                    height: 470px;
                }

                &::-webkit-scrollbar {
                    width: 9px;
                    background-color: #191919;
        
                    &-thumb {
                      background-color: #ffb300;
                    }
        
                  }

                h2 {
                    font-family: var(--anton);
                    font-size: 44px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 0 !important;
                    color: var(--pink);
                }

                h3 {
                    font-family: var(--geologica);
                    font-size: 30px;
                    font-weight: 400;
                    text-transform: uppercase;
                    margin: 0 !important;
                }
                p{
                    font-family: var(--geologica);
                    font-size: 18px;
                }
                .features__list{
                    p{
                        font-size: 15px;
                        color: #ffb300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:1024px) {
    .pricing__section__main {
        .about__sub__section {}
    }
}

@media only screen and (max-width:768px) {
    .pricing__section__main {
        .about__sub__section {
            padding: 0 30px;
            .pricing__card__container {
                .pricing__card{
                    width: calc(50% - 20px);
                    padding: 12px !important;
                    h2{
                        font-size: 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:525px) {
    .pricing__section__main {
        .about__sub__section {
            padding: 0 30px;
            .pricing__card__container {
                .pricing__card{
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (min-width:1461px) {
    .pricing__section__main {
        .about__sub__section {}
    }
}

// @media only screen and (min-width:1700px){
//     .pricing__section__main{
//         .about__sub__section{
//             .flex__section{
//                 padding: 1rem 300px;
//             }
//         }
//     }
// }